<!--
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-13 13:23:18
 * @LastEditTime: 2020-05-11 15:36:30
 -->
<template>
  <div>
    <div class="box_top">
      <Header class="page_hea" />
    </div>
    <router-view />
    <div class="bottom_footer">
      <Footer />
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import bg_su from '@/assets/imgs/successful/bg_su.png';

export default {
  components: {
    Header,
    Footer,
  },
  props: {},
  data() {
    return {
      bg_su,
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  updated() {
  },
  methods: {},
};
</script>
<style rel="stylesheet/less" lang="less" scoped>
.box_top {
  width: 100%;
  height: 80px;
  background: linear-gradient(
    270deg,
    rgba(252, 103, 81, 1) 0%,
    rgba(246, 71, 77, 1) 100%
  );
}
</style>
